import React from "react";
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import Img from "gatsby-image"
import SEO from "../components/seo"
import Map from "../components/map";
import './aboutus.css'

export default ({data: {datoCmsAboutuspage: p}}) => {
  return <Layout>
    <SEO title='About us' seo={p.seoMetaTags}/>
    <Img fluid={p.image.fluid}/>
    <div className="font-title text-3xl uppercase my-8 text-center tracking-wide">About us</div>
    <div className='px-2 lg:px-32 mb-8 md:mb-32'>
      <div className='font-sans font-light my-2 md:my-8'
           dangerouslySetInnerHTML={{__html: p.description}}/>
      <div className="flex flex-wrap my-4">
        <div className="fulltext flex-1 flex flex-col mr-4" style={{minWidth: 200}}>
          <div className="font-title text-xl uppercase tracking-wide my-4">The Brand</div>
          <div className='font-sans font-light' dangerouslySetInnerHTML={{__html: p.branddescription}}/>
        </div>
        <div className="fulltext flex-1 flex flex-col items-start" style={{minWidth: 200}}>
          <div className="font-title text-xl uppercase tracking-wide my-4">Services</div>
          <div className='font-sans font-light' dangerouslySetInnerHTML={{__html: p.servicesdescription}}/>
          <Link to='/services/'
                className='bg-yellow-700 font-title tracking-wide text-white my-2 px-4 py-2 no-underline'>Learn
            More</Link>

        </div>
      </div>
      <div className="font-title text-xl uppercase tracking-wide my-4">Location</div>
      <Map location={p.location}
           zoom={10}
           height={300}
           points={[{name: 'LookStyler', location: p.location}]}
      />
      <div className="font-title text-lg uppercase my-2">Americas Tower, New York</div>
      <div className="flex mt-8">
        <div className="flex flex-col w-full lg:w-1/2 justify-center">
          <div className="font-title text-3xl uppercase tracking-wide">Download our app!</div>
          <div className="font-sans font-light mb-2">LookStyler is now available on Android and iOS</div>
          <a href={p.googlelink} target='_blank' rel="noopener noreferrer">
            <Img fixed={p.googleimage.fixed}/>
          </a>
          <a href={p.applelink} target='_blank' rel="noopener noreferrer">
            <Img fixed={p.appleimage.fixed}/>
          </a>
        </div>
        <Img className='w-full lg:w-2/3 hidden lg:block' fluid={p.downloadimage.fluid}/>
      </div>
    </div>
  </Layout>;
}

export const query = graphql`
    query AboutUs {
        datoCmsAboutuspage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            image {
                fluid(maxWidth: 1500, imgixParams: {fit: "crop", w:"1000", h:"300", crop:"top"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            description
            branddescription
            servicesdescription
            location{
                longitude
                latitude
            }
            downloadimage {
                fluid(maxWidth: 1500) {
                    ...GatsbyDatoCmsFluid
                }
            }
            appleimage {
                fixed(width: 150, imgixParams: { fm: "jpg", auto: "compress" }) {
                    ...GatsbyDatoCmsFixed
                }
            }
            googleimage {
                fixed(width: 150, imgixParams: { fm: "jpg", auto: "compress" }) {
                    ...GatsbyDatoCmsFixed
                }
            }
            applelink
            googlelink
        }
    }

`